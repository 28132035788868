import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/officialadmin/message/list',
        params
    })
}

export function read(data) {
    return request({
        url: '/common-service/officialadmin/message/signRead',
        method: 'POST',
        data
    })
}

export function remove(data) {
    return request({
        url: '/common-service/officialadmin/message/delete',
        method: 'POST',
        data
    })
}
